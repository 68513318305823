"use client"
import React, { useState } from 'react'

import Container from '@/ui/container'
import Image from 'next/image';
import { SwiperSlide } from 'swiper/react';
import Carousel from '@/ui/carousel/carousel';
import { getIcon } from '@/lib/get-icon'
import Link from 'next/link'
import Button from '@/app/ui/button'
import bannerIcons from '../icons/banner-icons'
import commonIcons, { Bannercenter, Buttonarrow } from '../icons/common-icons'
import YouTubeVideo from '../modal/youtubeVideo'
import ReactPlayer from 'react-player'

function IndexBanner({ homeConent }: any) {


  const staticElem = {
    buttonOne: "Book a Demo",
    buttonTwo: "Explore VitalNxtai",
    square: '#26CEC7',
    astrich: '#C84E89',
  };

  const joinProps = { staticElem }
  const [showModal, setShowModal] = useState(false);
  let [isRequestOpen, setIsRequestOpen] = useState(false)
  function closeRequestModal() {
    setIsRequestOpen(false);
  }
  function openRequestModal() {
    setIsRequestOpen(true);
  }


  return (
    <div className=" bg-no-repeat max-w-full pt-[150px] xs:pt-4 pb-5 w-screen bg-[#D3E3FF]">
      <Container className="max-w-full px-10 xl:px-[75px] xs:px-5">
        <section className="">
          <div className="container xs:px-0 px-6 py-10 mx-auto xs:py-2">
            <h1 className="text-[50px] xs:text-[30px] xs:leading-[45px] xs:py-3 xs:pt-11 text-center font-semibold text-[#001C4D] capitalize 2xl:text-[72px] xl:text-[60px] 2xl:leading-[100px] leading-[80px]">Unlock the Power of
              <br /> <span className='text-[#008F7F]'> Health Data </span></h1>

            <p className="text-[20px] xs:w-[100%] text-center font-semibold text-[#001C4D] 2xl:text-[24px] 2xl:w-[47%] w-[60%] xl:my-3 mx-auto">Harness our dynamic Health API ecosystem and leverage real-time API integration to enhance your app.</p>
            <Link href={"/sign-in"}><Button className="w-full !h-[46px] xs:mt-3 xs:max-w-[200px] xl:min-w-[200px] mx-auto shadow-lg px-6 py-2.5 text-sm font-medium tracking-wider text-white transition-colors duration-300 transform md:w-auto md:mt-4 focus:outline-none !bg-[#00389B] !rounded-[50px] hover:bg-gray-700 focus:ring focus:ring-gray-300 focus:ring-opacity-80">
              Get Started  <Buttonarrow />
            </Button></Link>
            <div className="mt-8 xl:mt-12 lg:flex lg:items-center relative xs:pb-[10px] pb-[120px] xs:pt-[10px] pt-[60px]">
              <div className="grid w-full grid-cols-1 gap-8 lg:w-1/3 xl:gap-16 md:grid-cols-2 sm:grid-cols-2">


                <div className="border-[4px] border-[#F4F4F4]  rounded-xl absolute xs:relative xs:mx-auto w-min xs:top-0 xs:left-0 top-[5%] 2xl:left-[15%] left-[3%]">
                  <div className="flex flex-col p-5 rounded-xl bg-[#DCF5FF] shadow-xl -translate-x-[1rem] -translate-y-4 xl:min-w-[300px] min-w-[300px] md:w-auto">
                    <p className="text-[#0080B7] text-[24px] xl:text-[20px] xs:text-[16px] text-center font-[500] leading-[33px]">
                      Quick and easy integration of medical devices
                    </p>
                  </div>
                </div>

                <div className="border-[4px] border-[#F4F4F4]  rounded-xl absolute xs:relative xs:mx-auto w-min bottom-[30px] xs:top-0 xs:left-0 2xl:left-[17%] left-[8%]">
                  <div className="flex flex-col p-5 rounded-xl bg-[#FFF2E7] shadow-xl -translate-x-[1rem] -translate-y-4 xl:min-w-[300px] min-w-[300px] md:w-auto">
                    <p className="text-[#A54F00] text-[24px] xl:text-[20px] xs:text-[16px] text-center font-[500] leading-[33px]">
                      Empowering guided interpretation health decisions
                    </p>
                  </div>
                </div>

              </div>



              <div className="lg:flex lg:w-1/3 lg:justify-center sm:w-[55%] sm:mx-auto">
                {/* <Image width={200} height={200} className="w-[28rem] h-[28rem] flex-shrink-0 object-cover xl:w-[34rem] xl:h-[34rem] rounded-full" src="/images/home/banner/banner-img-3.webp" alt="" /> */}
                <Bannercenter />
              </div>

              <div className="grid w-full grid-cols-1 gap-8 lg:w-1/3 xl:gap-16 md:grid-cols-2  sm:grid-cols-2">

                <div className="border-[4px] border-[#F4F4F4]  rounded-xl xs:relative absolute xs:mx-auto w-min -top-[3%] xs:top-0 xs:right-0 2xl:right-[15%] right-[2%]">
                  <div className="flex flex-col p-5 rounded-xl bg-[#E9E7FF] shadow-xl -translate-x-[1rem] -translate-y-4 xl:min-w-[300px] min-w-[300px] md:w-auto">
                    <p className="text-[#0F00BA] text-[24px] xl:text-[20px] xs:text-[16px] text-center font-[500] leading-[33px]">
                      Al-driven data analysis for tailored recommendations
                    </p>
                  </div>
                </div>

                <div className="border-[4px] border-[#F4F4F4]  rounded-xl absolute xs:mx-auto xs:relative w-min top-[35%] xs:top-0 xs:right-0 2xl:right-[8%] right-[2%]">
                  <div className="flex flex-col p-5 rounded-xl bg-[#E0FFFB] shadow-xl -translate-x-[1rem] -translate-y-4 xl:min-w-[300px] min-w-[300px] md:w-auto">
                    <p className="text-[#007F71] text-[24px] xl:text-[20px] xs:text-[16px] text-center font-[500] leading-[33px]">
                      Accelerate integration of 100+ devices with a single API
                    </p>
                  </div>
                </div>

                <div className="border-[4px] border-[#F4F4F4]  rounded-xl absolute xs:mx-auto xs:relative w-min bottom-[2%] xs:top-0 xs:right-0 2xl:right-[18%] right-[9%]">
                  <div className="flex flex-col p-5 rounded-xl bg-[#FFEAEC] shadow-xl -translate-x-[1rem] -translate-y-4 xl:min-w-[300px] min-w-[300px] md:w-auto">
                    <p className="text-[#C03744] text-[24px] xl:text-[20px] xs:text-[16px] text-center font-[500] leading-[33px]">
                      Leading health and wellness data integration platform
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>




      </Container>



    </div>
  )
}

export default IndexBanner
