"use client"
import React, { useState } from 'react'
import Container from '@/ui/container'
import Image from 'next/image';

function WorkProcess({ homeConent }: any) {


  const staticElem = {
    buttonOne: "Book a Demo",
    buttonTwo: "Explore VitalNxtai",
    square: '#26CEC7',
    astrich: '#C84E89',
  };

  const joinProps = { staticElem }
  const [showModal, setShowModal] = useState(false);
  let [isRequestOpen, setIsRequestOpen] = useState(false)
  function closeRequestModal() {
    setIsRequestOpen(false);
  }
  function openRequestModal() {
    setIsRequestOpen(true);
  }


  return (
    <div className=" bg-no-repeat max-w-full  xs:pt-4 pb-5 w-screen bg-[#F2F5F8]">
      <Container className="max-w-full px-10 xl:px-[75px] xs:px-5">
        <section className="">
          <div className="container xs:px-0 px-6 py-10 mx-auto xs:py-2">
            <h1 className="text-[45px] text-center font-[500] text-[#001C4D] 2xl:text-[56px] xs:text-[30px]">How VitalNXT.ai Works</h1>

            <p className="text-[18px] text-center font-[400] text-[#001C4D] lg:text-[20px] 2xl:w-[47%] w-[60%] mx-auto xs:w-[100%]"> Vitalnxt.ai aims to improve patient outcomes by leveraging AI to provide proactive, personalized, and real-time healthcare solutions</p>


            <div className="grid grid-cols-1 mt-8">
              <div className="timeline relative">
                <div className="timeline-item">
                  <div className="grid md:grid-cols-2">
                    <div className="">
                      <div className="duration date-label-left ltr:float-right rtl:float-right flex items-center justify-end md:me-7 relative">
                        <Image src={'/images/works/works1.png'} alt="CRM logo" width={392} height={300} className="pr-7" />
                      </div>
                    </div>
                    <div className="mt-4 md:mt-0 flex items-center">
                      <div className="event event-description-right ltr:float-left xs:pl-0 pl-[20px] rtl:float-right ltr:text-left rtl:text-right md:ms-7">
                        <h5 className="2xl:text-[36px] text-[30px] xs:text-[25px] text-[#001C4D] mb-1 font-[500]">Get Developer Credentials</h5>
                        <p className="timeline-subtitle xs:text-[20px] text-[24px] mt-1 mb-0 text-[#6D7E9A]">Register and select the custom solution you need </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="timeline-item xs:mt-[20px] mt-[80px] pt-4">
                  <div className="grid md:grid-cols-2">
                    <div className="md:order-1 order-2  flex items-center justify-end">
                      <div className="event event-description-left get-f pr-9 xs:pr-0 ltr:float-left rtl:float-right ltr:text-right rtl:text-left md:me-7 xs:mt-[15px]">
                        <h5 className="2xl:text-[36px] text-[30px] xs:text-[25px] text-[#001C4D] mb-1 font-[500]">Authenticate User</h5>
                        <p className="timeline-subtitle xs:text-[20px] text-[24px] mt-1 mb-0 text-[#6D7E9A]">Securely authenticate users on <span className='text-[#008F7F]'> VitalNXT.ai. </span></p>
                      </div>
                    </div>
                    <div className="md:order-2 order-1">
                      <div className="duration duration-right md:ms-7 relative">
                        <Image src={'/images/works/works2.png'} alt="CRM logo" width={392} height={300} className="" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="timeline-item xs:mt-[20px] mt-[80px] pt-4">
                  <div className="grid md:grid-cols-2">
                    <div className="">
                      <div className="duration date-label-left ltr:float-right rtl:float-right flex items-center justify-end md:me-7 relative">
                        <Image src={'/images/works/works3.png'} alt="CRM logo" width={392} height={300} className="pr-7" />
                      </div>
                    </div>
                    <div className="mt-4 md:mt-0  flex items-center">
                      <div className="event event-description-right ltr:float-left xs:pl-0 pl-[20px] rtl:float-right ltr:text-left rtl:text-right md:ms-7">
                        <h5 className="2xl:text-[36px] text-[30px] xs:text-[25px] text-[#001C4D] mb-1 font-[500]">Setup Webhook Endpoint</h5>
                        <p className="timeline-subtitle xs:text-[20px] text-[24px] mt-1 mb-0 text-[#6D7E9A]">Create a webhook endpoint to receive data.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="timeline-item xs:mt-[20px] mt-[80px] pt-4">
                  <div className="grid md:grid-cols-2">
                    <div className="md:order-1 order-2  flex items-center justify-end">
                      <div className="event event-description-left get-f pr-9 xs:pr-0 ltr:float-left rtl:float-right ltr:text-right rtl:text-left md:me-7 xs:mt-[15px]">
                        <h5 className="2xl:text-[36px] text-[30px] xs:text-[25px] text-[#001C4D] mb-1 font-[500]">Configure Data Transfer</h5>
                        <p className="timeline-subtitle xs:text-[20px] text-[24px] mt-1 mb-0 text-[#6D7E9A]">
                          Set up <span className='text-[#008F7F]'> VitalNXT.ai. </span> to send data to your endpoint.
                        </p>
                      </div>
                    </div>
                    <div className="md:order-2 order-1">
                      <div className="duration duration-right md:ms-7 relative">
                        <Image src={'/images/works/works4.png'} alt="CRM logo" width={392} height={300} className="" />
                      </div>
                    </div>
                  </div>
                </div>


                <div className="timeline-item xs:mt-[20px] mt-[80px] pt-4">
                  <div className="grid md:grid-cols-2">
                    <div className="">
                      <div className="duration date-label-left ltr:float-right rtl:float-right flex items-center justify-end md:me-7 relative">
                        <Image src={'/images/works/works5.png'} alt="CRM logo" width={392} height={300} className="pr-7" />
                      </div>
                    </div>
                    <div className="mt-4 md:mt-0  flex items-center">
                      <div className="event event-description-right ltr:float-left xs:pl-0 pl-[20px] rtl:float-right ltr:text-left rtl:text-right md:ms-7">
                        <h5 className="2xl:text-[36px] text-[30px] xs:text-[25px] text-[#001C4D] mb-1 font-[500]">Receive Data</h5>
                        <p className="timeline-subtitle xs:text-[20px] text-[24px] mt-1 mb-0 text-[#6D7E9A]">Start receiving real-time data at your endpoint!</p>
                      </div>
                    </div>
                  </div>
                </div>




              </div>
            </div>


          </div>
        </section>




      </Container>



    </div>
  )
}

export default WorkProcess
