"use client"
import { getIcon } from '@/lib/get-icon'
import Button from '@/app/ui/button'
import Container from '@/ui/container'
import React from 'react'
import homeIcons from '../icons/home-icons'
import { useParallax } from 'react-scroll-parallax'
import Link from 'next/link'
import { ROUTES } from '@/utils/routes'
import { Buttonarrow, Wearables } from '../icons/common-icons'
import Image from 'next/image'

export default function Simplifies({ title }: any) {


  return (
    <Container className="relative px-[40px] xs:px-5 2xl:px-[75px] max-w-full flex flex-col gap-6 justify-center items-start bg-[#FFE7CA] 2xl:min-h-[1000px] md:min-h-[600px] overflow-hidden">
      <div className="py-16  xs:py-0 ">
        <div className="m-auto px-6 pb-[70px] xs:px-0 xs:pb-[10px]">

          <div className="lg:flex justify-between items-center">
            <div className="lg:w-6/12 lg:p-0 p-7 xs:px-0">

              <div className="2xl:text-[56px] text-[45px] xs:text-2xl font-[500] xs:text-center text-[#001C4D] xs:px-5 leading-[67px]"> Simplify Your Digital Life with <span className='text-[#008F7F]'> Vitalnxt.ai </span></div>

              <p className='2xl:text-[24px] text-[21px] text-[#001C4D] mt-3 leading-[33px]'>Vitalnxt.ai streamlines your digital experience by integrating all your devices into one app, ensuring seamless connectivity and easy management from a single platform.</p>
              <p className='2xl:text-[24px] text-[21px] text-[#001C4D] leading-[33px]'>
                Say goodbye to juggling multiple <span className='text-[#008F7F]'> apps—Vitalnxt.ai simplifies your life. </span>
              </p>

            </div>
            <div className="lg:w-6/12 order-2">
              <div className="flex items-center justify-center">
                <div className="grid grid-cols-1 gap-[40px] gap-y-[3.5rem] md:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 sm:grid-cols-2 xl:grid-cols-2">
                  <div className='absolute 2xl:top-0 top-6 right-0 xs:hidden'>
                    <Image src={'/images/simplifies.png'} alt="simplifies" width={916} height={966} className=" 2xl:w-auto lg:w-[500px] pr-0" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </Container>
  )
}
